<template>
  <v-container fluid>
    <v-row no-gutters align="center" justify="space-between">
      <titleBreadCrumbsComponent
        :breadCrumbs="breadCrumbs"
        :title="$t('TXT_CONFIG_COMPANY')"
        :hasTooltip="false"
      />
      <router-link to="/main/company-select">
        <prd-btn
          :title="$t('TXT_BACK')"
          :icon="'mdi-arrow-left'"
          :leftIcon="true"
        />
      </router-link>
    </v-row>

    <h4>{{ $t("TXT_ADD_COMPANY") }}</h4>

    <v-card class="pa-4 mt-4" :disabled="createCompanyLoading">
      <span>{{ $t("TXT_INSERT_INFORMATIONS") }}</span>

      <!-- INPUT AREA -->
      <v-row no-gutters class="my-6">
        <v-col cols="12" md="4" class="mr-4">
          <Prd-text-field
            v-model="companyName"
            :title="$t('TXT_TRADE_NAME')"
            :placeholder="$t('TXT_INSERT_TRADE_NAME')"
          />
        </v-col>
        <v-col class="mr-4">
          <Prd-text-field
            v-model="city"
            :title="$t('TXT_COUTY')"
            :placeholder="$t('TXT_INSERT_COUTY')"
          />
        </v-col>
        <v-col class="mr-4">
          <Prd-combo-box
            v-model="country"
            :items="countryList"
            :title="$t('TXT_COUNTRY')"
            :loading="countryListLoading"
            :disabled="countryListLoading"
            :placeholder="$t('TXT_SELECT')"
            item-id="countryCode"
            item-text="country"
          />
        </v-col>
        <v-col>
          <Prd-combo-box
            v-model="sectorAction"
            :items="sectorActionList"
            :title="$t('TXT_SECTOR_2')"
            :placeholder="$t('TXT_SELECT_SECTOR')"
          />
        </v-col>
      </v-row>

      <v-row no-gutters justify="end">
        <Prd-btn
          @click="createCompany"
          :title="$t('TXT_ADD_COMPANY_2')"
          :loading="createCompanyLoading"
          :disabled="
            !companyName ||
            !city ||
            !country ||
            !sectorAction ||
            createCompanyLoading
          "
        />
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import titleBreadCrumbsComponent from "@/Design_System/common/title.vue";
import PrdTextField from "@/components/common/prd-text-field.vue";
import PrdComboBox from "@/Design_System/common/prd-comboBox.vue";
import PrdBtn from "@/Design_System/common/prd-btn.vue";
import service from "@/service/company-select/index.js";
const Service = new service();
export default {
  name: "create-company",
  components: {
    titleBreadCrumbsComponent,
    PrdTextField,
    PrdComboBox,
    PrdBtn,
  },
  data() {
    return {
      breadCrumbs: [
        {
          text: this.$i18n.t("TXT_SELECTION_COMPANY"),
        },
        {
          text: this.$i18n.t("TXT_ADD_COMPANY_2"),
        },
      ],

      companyName: null,
      city: null,
      country: null,
      sectorAction: null,

      countryList: [],
      sectorActionList: [
        {
          text: this.$i18n.t("TXT_AUTONOMOUS"),
          value: 0,
        },
        {
          text: this.$i18n.t("TXT_TRADE"),
          value: 10,
        },
        {
          text: this.$i18n.t("TXT_INDUSTRY"),
          value: 20,
        },
        {
          text: this.$i18n.t("TXT_SERVICES"),
          value: 30,
        },
        {
          text: this.$i18n.t("TXT_TRADE_SERVICES"),
          value: 11,
        },
        {
          text: this.$i18n.t("TXT_INDUSTRY_TRADE"),
          value: 21,
        },
      ],

      countryListLoading: false,
      createCompanyLoading: false,
    };
  },

  computed: {
    userData() {
      return this.$store?.getters?.userData ?? null;
    },
  },

  methods: {
    async getCountries() {
      this.countryListLoading = true;

      try {
        let response = await Service.listCountries();
        this.transformCountries(response?.data ?? []);
      } catch (error) {
        console.log(error);
      }

      this.countryListLoading = false;
    },

    transformCountries(countrys) {
      this.countryList = countrys.map((el) => ({
        text: el.country,
        value: el.idCountries,
      }));
    },

    async createCompany() {
      this.createCompanyLoading = true;

      let request = {
        IdParceiro: "",
        idCountry: this.country.value,
        setor: this.sectorAction.value,
        idUsuario: this.userData.id,
        nomeRazaoSocial: this.companyName,
      };

      try {
        let response = await Service.createCompany(request);

        let company = response?.data ?? null;

        if (company) {
          this.setCompany(company);
          this.$store.commit("snackbarV2/set", {
            message: "Empresa criada com sucesso !",
            type: "success",
          });
        }
      } catch (error) {
        console.log(error);
        this.$store.commit("snackbarV2/set", {
          message: "Erro ao criar empresa",
          type: "error",
        });
      }

      this.createCompanyLoading = false;
    },

    setCompany(company) {
      let userData = {
        ...this.userData,
        idCompany: company.idEmpresa,
        companyName: company.nomeRazaoSocial,
      };

      this.$store.dispatch("setUser", userData);
      this.$router.push("/main/home");
    },
  },

  created() {
    this.getCountries();
  },
};
</script>