<template>
  <div>
    <span @click.stop="modalOpen = true" class="validation-button mr-4">
      <v-icon :class="setStatusColor(lastAction)"
        >mdi-traffic-light-outline</v-icon
      >
      <span :class="setStatusColor(lastAction)">{{
        $i18n.t("TXT_RULECONDITION")
      }}</span>
    </span>

    <v-dialog v-model="modalOpen" persistent>
      <v-card class="pa-4">
        <v-row
          no-gutters
          align="center"
          justify="space-between"
          class="mb-4 pt-4"
        >
          <h4>{{ $i18n.t("TXT_RULECONDITION").toUpperCase() }}</h4>
          <v-icon @click="modalOpen = false">mdi-close</v-icon>
        </v-row>

        <div class="modal-title mb-4 py-2">
          <span class="font-weight-bold">{{ productName }}</span>
        </div>

        <Prd-loading-circular v-if="isLoading" />
        <Expandable-table v-else :groups="groups" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import service from "@/service/pricer/product-list.js";
import PrdLoadingCircular from "@/components/common/prd-loading-circular.vue";
const Service = new service();
import { formatMonetary } from "@/utils/format-toMonetary.js";
import { formatPercentage } from "@/utils/format-percentage.js";
import ExpandableTable from "./components/expandable-table.vue";

export default {
  components: { PrdLoadingCircular, ExpandableTable },
  props: ["idEnterprisePricesProjection", "productName", "lastAction"],
  data() {
    return {
      modalOpen: false,
      isLoading: false,
    };
  },

  watch: {
    modalOpen: {
      handler(value) {
        if (value) this.getRulePath();
        else this.groups = [];
      },
      immediate: true,
    },
  },

  methods: {
    formatPercentage,
    formatMonetary,

    async getRulePath() {
      this.isLoading = true;

      try {
        let response = await Service.getRulePath(
          this.idEnterprisePricesProjection
        );

        this.groups = response?.data?.result ?? [];
      } catch (error) {
        this.$handleError(error, this.$i18n.t("TXT_ERROR_GET_RULE_PATH"));
      } finally {
        this.isLoading = false;
      }
    },

    setStatusColor(status) {
      if (status == "TXT_APPROVED" || status == 0) return "success-color";
      else if (status == "TXT_NOT_APPLIED" || status == 2) return "";
      return "error-color";
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.validation-button {
  display: flex;
  align-items: center;
  :hover {
    opacity: 0.5;
  }
}

.success-color {
  color: #1d8527;
}
.error-color {
  color: #b00020;
}
</style>
