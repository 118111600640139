<template>
  <v-data-table
    :headers="headers"
    :items="groups"
    show-expand
    item-key="idRule"
    :items-per-page="-1"
    hide-default-footer
    :no-data-text="$t('TXT_NO_DATA_FOUND_DISPLAY')"
    :no-results-text="$t('TXT_NO_DATA_FOUND_DISPLAY')"
    class="expandable-table"
  >
    <template v-slot:[`item.name`]="{ item }">
      <td class="text-center bg-grey mb-4">
        {{ item.name }}
      </td>
    </template>
    <template v-slot:[`item.prePrice`]="{ item }">
      <td class="text-center bg-grey mb-4">
        {{ formatMonetary(item.prePrice) }}
      </td>
    </template>
    <template v-slot:[`item.posPrice`]="{ item }">
      <td class="text-center bg-grey mb-4">
        {{ formatMonetary(item.posPrice) }}
      </td>
    </template>
    <template v-slot:[`item.preMargin`]="{ item }">
      <td class="text-center bg-grey mb-4">
        {{ formatPercentage(item.preMargin) }}
      </td>
    </template>
    <template v-slot:[`item.posMargin`]="{ item }">
      <td class="text-center bg-grey mb-4">
        {{ formatPercentage(item.posMargin) }}
      </td>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <td class="text-center bg-grey mb-4" :class="setStatusColor(item.status)">
        {{ $t(item.statusMessage) }}
      </td>
    </template>
    <template v-slot:expanded-item="{ item }">
      <td :colspan="headers.length" class="reset-expanded-item">
        <div class="expanded-item-content">
          <v-divider color="#666666" />
          <Rule-table :rules="item.rules" />
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { formatPercentage } from "@/utils/format-percentage";
import { formatMonetary } from "@/utils/format-toMonetary";
import RuleTable from "./rule-table.vue";
export default {
  props: { groups: { type: Array, default: () => [] } },
  components: { RuleTable },
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t("TXT_GROUP_2"),
          align: "center",
          sortable: true,
          value: "name",
        },
        {
          text: this.$i18n.t("TXT_PRE_PRICE_2"),
          align: "center",
          sortable: true,
          value: "prePrice",
        },
        {
          text: this.$i18n.t("TXT_POS_PRICE_2"),
          align: "center",
          sortable: true,
          value: "posPrice",
        },
        {
          text: this.$i18n.t("TXT_PRE_MARGEM_2"),
          align: "center",
          sortable: true,
          value: "preMargin",
        },
        {
          text: this.$i18n.t("TXT_POS_MARGEM_2"),
          align: "center",
          sortable: true,
          value: "posMargin",
        },
        {
          text: this.$i18n.t("TXT_STATUS"),
          align: "center",
          sortable: true,
          value: "status",
        },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  methods: {
    formatPercentage,
    formatMonetary,
    setStatusColor(status) {
      if (status == "TXT_APPROVED" || status == 1) return "success-color";
      else if (status == "TXT_NOT_APPLIED" || status == 2) return "";
      return "error-color";
    },
  },
};
</script>

<style lang="scss">
.success-color {
  color: #1d8527;
}
.error-color {
  color: #b00020;
}
.bg-grey {
  background-color: $neutral-color-high-light;
}
.expandable-table {
  tr td:last-child {
    background-color: $neutral-color-high-light;
  }
}
.reset-expanded-item {
  background-color: white !important;
  padding: 0 !important;
}
.expanded-item-content {
  padding: 16px 8px 0px 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid #bab8b8;
  border-left: 1px solid #bab8b8;
  border-right: 1px solid #bab8b8;
  max-height: 260px;
  overflow: auto;
}
</style>
