import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// Highcharts chart and maps
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import stockInit from "highcharts/modules/stock";
import mapInit from "highcharts/modules/map";
import addMapUSAll from "./highcharts/map-us-all";
import addMapWorld from "./highcharts/map-world";
import highchartsMore from "highcharts/highcharts-more";
import { UserAgentApplication } from "msal";
import Hotjar from "@hotjar/browser";
import Predify_Styles_Util from "./Design_System/modulos/predifyStylesUtil";
import { handleError } from "@/utils/handleError.js";
import "@/utils/filters.js";
import money from "v-money"
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import specific icons */
// import { faChartColumn } from '@fortawesome/free-solid-svg-icons'
import {
  faHome,
  faMoneyBillTrendUp,
  faWallet,
  faCreditCard,
  faDollarSign,
  faFileArrowUp,
  faChartLine,
  faCircleUser,
  faTicket,
  faRobot,
  faUserGear,
  faTrashCan,
  faUser,
  faAt,
  faEyeSlash,
  faEye,
  faBuilding,
  faChevronDown,
  faFilter,
  faUserSecret,
  faUsers,
  faBuildingUser,
  faTableColumns,
  faArrowTrendUp,
  faCalculator,
  faLandmark,
  faRightFromBracket,
  faGear,
  faGears,
  faHouseUser,
  faMagnifyingGlassChart,
  faChartPie,
  faClipboardList,
  faFutbol,
  faHotel,
  faBusinessTime,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// importing frontend local translations
import i18n from "./i18n";
/* add icons to the library */
library.add(
  faUserSecret,
  faUsers,
  faBuildingUser,
  faHome,
  faUser,
  faEye,
  faEyeSlash,
  faAt,
  faBuilding,
  faMoneyBillTrendUp,
  faWallet,
  faCreditCard,
  faDollarSign,
  faFileArrowUp,
  faChartLine,
  faCircleUser,
  faLandmark,
  faRightFromBracket,
  faTicket,
  faRobot,
  faUserGear,
  faChevronDown,
  faTrashCan,
  faFilter,
  faTableColumns,
  faArrowTrendUp,
  faGear,
  faGears,
  faCalculator,
  faHouseUser,
  faMagnifyingGlassChart,
  faChartPie,
  faClipboardList,
  faFutbol,
  faHotel,
  faBusinessTime,
  faWrench
);

const msalConfig = {
  auth: {
    clientId: "6dbc584d-12df-41cd-97a5-9639edb18909",
    authority:
      "https://login.microsoftonline.com/8bc97f9a-ea86-472e-8ab7-19db58c4c8af",
    redirectUri: "https://app.predify.me/uplsso",
  },
};

const msalInstance = new UserAgentApplication(msalConfig);
Vue.prototype.$msalInstance = msalInstance;
Vue.prototype.$handleError = handleError;

if (process.env.NODE_ENV != "development") {
  const hotjarId = process.env.VUE_APP_HOTJAR_ID;
  const hotjarVersion = process.env.VUE_APP_HOTJAR_VERSION;
  const clarityId = process.env.VUE_APP_HOTJAR_CLARITY;

  if (hotjarId && hotjarVersion) {
    Hotjar.init(hotjarId, hotjarVersion);
  } else {
    console.error("Hotjar ID or Version is not defined");
  }

  if (clarityId) {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", clarityId);
  } else {
    console.error("Clarity ID is not defined");
  }
}

// Maps from Highcharts
stockInit(Highcharts);
mapInit(Highcharts);
addMapWorld(Highcharts);
addMapUSAll(Highcharts);
highchartsMore(Highcharts);

// Highcharts lib
Vue.use(HighchartsVue);

// Design system
Vue.use(Predify_Styles_Util);
Vue.use(money)

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  Predify_Styles_Util,
  render: (h) => h(App),
}).$mount("#app");
