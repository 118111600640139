var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rules,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.order)+" ")])]}},{key:"item.oldPrice",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatMonetary(item.oldPrice))+" ")])]}},{key:"item.currentPrice",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatMonetary(item.currentPrice))+" ")])]}},{key:"item.oldMargin",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatPercentage(item.oldMargin))+" ")])]}},{key:"item.currentMargin",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatPercentage(item.currentMargin))+" ")])]}},{key:"item.nameCondition",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t(item.nameCondition))+" ")])]}},{key:"item.statusMessage",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center",class:_vm.setStatusColor(item.statusMessage)},[_vm._v(" "+_vm._s(_vm.$t(item.statusMessage))+" ")])]}},{key:"item.messageBlockedRuleCondition",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.messageBlockedRuleCondition ? _vm.$t(item.messageBlockedRuleCondition) : "-")+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }